import React, { useState } from "react";
import { Formik, Form } from "formik";
import { navigate } from "gatsby";

import {
  base,
  formContainer,
  formInput,
  formTextArea,
  formSubmit,
  message,
  copy,
} from "./DownloadOverview.module.scss";

import FormInput from "../components/FormInput";
import Button from "../components/Button";
import Link from "../components/Link";

import countries from "../utils/countries";
import { submitDownloadOverviewForm } from "../utils/api";
import { isValidEmail } from "../utils/misc";

const initialValues = {
  firstName: "",
  email: "",
  company: "",
  country: "",
};

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "This field is required";
  }

  if (!values.email) {
    errors.email = "This field is required";
  } else if (!isValidEmail(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.company) {
    errors.company = "This field is required";
  }

  if (!values.country) {
    errors.country = "This field is required";
  }

  return errors;
};

const Message = props => {
  const { heading, body } = props;

  return (
    <div className={message}>
      <h3>{heading}</h3>
      <p>{body}</p>
    </div>
  );
};

const DownloadOverviewForm = props => {
  const [success, setSuccess] = useState(null);

  const onSubmit = (values, { setSubmitting }) => {
    setTimeout(() => {
      submitDownloadOverviewForm(values)
        .then(() => {
          setSubmitting(false);
          setSuccess(true);
          setTimeout(() => setSuccess(null), 6000);
        })
        .catch(() => {
          setSubmitting(false);
          setSuccess(false);
          setTimeout(() => setSuccess(null), 4000);
        });
    }, 400);
  };

  if (success === true) {
    if (typeof window !== "undefined") {
      window.open("https://4363226.fs1.hubspotusercontent-na1.net/hubfs/4363226/Brandcrush_Capabilities%20Overview_Download.pdf", "_blank")
    }

    navigate("/brandcrush-for-business/retail-grocery");
  }

  if (success === false) {
    return (
      <Message
        heading="Oops, something went wrong!"
        body="Please check your details and try again."
      />
    );
  }

  return (
    <div className={base}>
      <Formik initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
        {({ isSubmitting }) => (
          <Form className={formContainer}>
            <FormInput name="firstName" label="First name" className={formInput} />
            <FormInput name="email" label="Email" className={formInput} />
            <FormInput name="company" label="Company name" className={formInput} />
            <FormInput
              name="country"
              label="Country"
              type="select"
              options={[
                { value: "", label: "" },
                ...countries.map(country => ({ value: country, label: country })),
              ]}
              className={formInput}
            />
            <small className={copy}>
              We're committed to your privacy. Brandcrush uses the information you provide to us
              to contact you about our relevant content, products, and services. You may
              unsubscribe from these communications at any time. For more information, check out
              our <Link href="/privacypolicy">Privacy Policy</Link>.
            </small>
            <div className={formSubmit}>
              <Button type="submit" disabled={isSubmitting}>
                Submit
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DownloadOverviewForm;
